<template>
  <v-combobox
    :items="availableServices"
    :placeholder="'Services'|trans"
    :rules="validation.required"
    item-text="name"
    item-value="guid"
    multiple
    :return-object="true"
    :value="value"
    color="rgba(0,0,0,.6)"
    :class="classList"
    outlined
    hide-details
    light
    dense
    @input="updateValue"
  >
  </v-combobox>
</template>

<script>
export default {
  name: "AvailableServices",
  props: {
    value: {
      type: Array,
    },
    classList: {
      type: String,
    },
  },
  data() {
    return {
      availableServices: [],
      validation: {
        required: [(v) => !!v || "Is required"],
      },
    };
  },
  mounted() {
    this.getAvailableServices();
  },
  methods: {
    updateValue: function (value) {
      this.$emit("input", value);
    },
    getAvailableServices() {
      let $this = this;

      fetch(process.env.VUE_APP_API_URL + "/services/list", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": this.$store.getters.getLocale,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.type !== "success") {
            this.localAlert = {
              type: "error",
              color: "red",
              message: data.message
                ? data.message
                : "Fetching services list failed!",
            };

            return false;
          }

          $this.availableServices = data.services;
        })
        .catch((error) => {
          console.error("Error:", error);

          this.localAlert = {
            type: "error",
            color: "red",
            message: error.message
              ? error.message
              : "Fetching services list failed!",
          };
        });
    },
  },
};
</script>

<style scoped></style>
